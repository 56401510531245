<template>
  <div>
    <h4>Vaccin</h4>
    
    <data-table
      :data-source="vaccins"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-vaccin"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
        
        
        
    <modal
      title="Ajout d'un vaccin"
      id="add-vaccin"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="add-espece">Espèce</label>
          <select
            v-model="espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
            name="add-espece"
            id="add-espece"
            class="form-control"
          >
            <option value="" />
            <option
              v-for="(e,i) in especes"
              :key="i"
              :value="e.uid"
            >
              {{ e.libelle }}
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="add-maladie">Maladie</label>
              <select
                v-model="maladie"
                v-select="{placeholder: 'Selectionnez la maladie'}"
                name="add-maladie"
                id="add-maladie"
                class="form-control"
              >
                <option value="" />
                <option
                  v-for="(m,i) in maladies"
                  :key="i"
                  :value="m.uid"
                >
                  {{ m.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="add-valence">Valence</label>
              <input
                id="add-valence"
                name="add-valence"
                type="text"
                class="form-control"
                v-model="valence"
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="add-primo">Primo vaccination</label>
              <input
                type="text"
                name="add-primo"
                id="add-primo"
                v-model="primo"
                class="form-control"
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="annuel"
                id="add-annuel"
              >
              <label
                class="form-check-label"
                for="add-annuel"
              >
                Rappel annuel?
              </label>
            </div>
          </div>
        </div>

                
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      title="Modification d'un vaccin"
      id="update-vaccin"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="update-espece">Espèce</label>
          <select
            v-model="espece"
            v-select="{placeholder: 'Selectionnez l\'espèce'}"
            name="update-espece"
            id="update-espece"
            class="form-control"
          >
            <option value="" />
            <option
              v-for="(e,i) in especes"
              :key="i"
              :value="e.uid"
            >
              {{ e.libelle }}
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="update-maladie">Maladie</label>
              <select
                v-model="maladie"
                v-select="{placeholder: 'Selectionnez la maladie'}"
                name="update-maladie"
                id="update-maladie"
                class="form-control"
              >
                <option value="" />
                <option
                  v-for="(m,i) in maladies"
                  :key="i"
                  :value="m.uid"
                >
                  {{ m.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="update-valence">Valence</label>
              <input
                id="update-valence"
                name="update-valence"
                type="text"
                class="form-control"
                v-model="valence"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div class="form-group">
              <label for="update-primo">Primo vaccination</label>
              <input
                type="text"
                name="update-primo"
                id="update-primo"
                v-model="primo"
                class="form-control"
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="annuel"
                id="update-annuel"
              >
              <label
                class="form-check-label"
                for="update-annuel"
              >
                Rappel annuel?
              </label>
            </div>
          </div>
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import {  ADD_VACCIN, UPDATE_VACCIN } from '../../../../graphql/sanitaire'
const Maladie = () => import('../../../../components/admin/sanitaire/medicament/maladieInline.vue')
const Actions = () => import('../../../../components/admin/sanitaire/medicament/vaccinActions.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
          espece: null,
            libelle: null,
            maladie: null,
            valence: null,
            primo: null,
            annuel: false,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedType: {
            handler(){
                if(this.selectedType !== null) {
                    this.libelle = this.selectedType.libelle
                    this.maladie = this.selectedType.maladie
                    this.espece = this.selectedType.espece
                    this.primo =  this.selectedType.primo
                    this.annuel = this.selectedType.annuel
                    this.valence = this.selectedType.valence
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.maladie= null
            this.valence= null
            this.primo= null
            this.annuel= false
            this.espece = null
            this.has_error = false
            this.error_msg = null
        },
        adding(){
            let data = {
                libelle: this.libelle,
                maladie: this.maladie,
                valence: this.valence,
                primo: this.primo,
                annuel: this.annuel,
                espece: this.espece
            }
            this.$apollo.mutate({
                mutation: ADD_VACCIN,
                variables: {
                    "vaccin": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Type analyse ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editing(){
            let data = {
                libelle: this.libelle,
                maladie: this.maladie,
                valence: this.valence,
                primo: this.primo,
                annuel: this.annuel,
                espece: this.espece
            }
            this.$apollo.mutate({
                mutation: UPDATE_VACCIN,
                variables: {
                    "vaccin": {
                        ...data
                    },
                    "uid": this.selectedType.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Vaccin ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
          especes: 'identification/especes',
            vaccins: 'sanitaire/vaccins',
            maladies: 'sanitaire/maladies',
            selectedType: 'selectedObject'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Espèce', name: 'espece', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Valence', name: 'valence', type: TEXT_TYPE},
                {label: 'Primo vaccination', name: 'primo', type: TEXT_TYPE},
                {label: 'Maladie', name: 'maladie', type: COMPONENT_TYPE, component: Maladie},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>

<style>

</style>